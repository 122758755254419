import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGTAGS = 'tags/SETISLOADINGTAGS';
export const SETTAGS = 'tags/SETTAGS';

export const getTags = (onSuccess = (data:any) => {}, onError = (data:any) => {}) => {
  return (dispatch) => {

    dispatch({ type: SETISLOADINGTAGS, isLoadingLocations: true });

    axios(`${config.endpoints.base_url}tags/coatrotags`).then((resp) => {
      dispatch({ type: SETISLOADINGTAGS, isLoadingTags: false });
      dispatch({ type: SETTAGS, tags: resp.data?.data?.shift() || [] });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const addTag = (tag, onSuccess = (data:any) => {}) => {
  return () => {
    axios.post( `${config.endpoints.base_url}tags`, { name: tag }).then((resp) => {
      onSuccess(resp);
    });
  };
};

export const addTagV2 = (
  tag: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.post(`${config.endpoints.base_url}tags`,  { name: tag });

      if (onSuccess) {
        onSuccess(data);
      }

    } catch (error) {
      let err = (error as any).message
      if (onError) {
        onError(err);
      }
    }
  };
};
