import {
  SETSIGNAGEVENDORS,
  SETVENDORPLAYER,
  SETSIGNAGEDEVICES,
  ADDSIGNAGEDEVICES,
  EDITSIGNAGEDEVICES,
  SETINTERRUPTS,
  DELETESIGNAGEDEVICES,
  SETISLOADINGINTERRUPTS,
  SETSIGNAGEPLAYERS,
  SETISLOADINGCHECKCONNECTION,
  SETISFETCHINGINTERRUPTS,
  SETINTERRUPTSLIST,
  SETNETWORK,
} from '../actions/signagevendors';
import { produce } from 'immer';

const initialState = {
  signagevendors: [],
  vendorplayers: [],
  signagedevices: [],
  interrupts: [],
  interruptsList: [],
  isLoadingInterrupts: false,
  isLoadingInterruptsList: false,
  signageplayers: [],
  isLoadingCheckConnection: false,
  network: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETSIGNAGEVENDORS:
      return { ...state, signagevendors: action.signagevendors }
    case SETVENDORPLAYER:
      return { ...state, vendorplayers: action.vendorplayers }
    case SETSIGNAGEDEVICES:
      return { ...state, signagedevices: action.signagedevices }
    case ADDSIGNAGEDEVICES:
      return { ...state, signagedevices: [...state.signagedevices, action.signagedevices] }
    case SETINTERRUPTS:
      return { ...state, interrupts: action.interrupts }
    case SETINTERRUPTSLIST:
      return { ...state, interruptsList: action.interruptsList }
    case DELETESIGNAGEDEVICES:
      return { ...state, signagedevices: state.signagedevices.filter(item => action.signagedevices !== item) };
    case EDITSIGNAGEDEVICES:
      return {
        ...state,
        signagedevices: state.signagedevices.map((signagedevice) => {
          if (signagedevice.feeder_id === action.signagedevices.feeder_id) {
            return {
              ...signagedevice,
              pair_tags: action.signagedevices.pair_tags
            };
          }

          return signagedevice;
        })
      };
    case SETNETWORK:
      return produce(state, draftState => {
        draftState.network = action.network;
      });
    case SETISLOADINGINTERRUPTS:
      return produce(state, draftState => {
        draftState.isLoadingInterrupts = action.isLoadingInterrupts;
      });
    case SETISFETCHINGINTERRUPTS:
      return produce(state, draftState => {
        draftState.isLoadingInterruptsList = action.isLoadingInterruptsList;
      });
    case SETSIGNAGEPLAYERS:
      return { ...state, signageplayers: action.signageplayers }
    case SETISLOADINGCHECKCONNECTION:
      return { ...state, isLoadingCheckConnection: action.isLoadingCheckConnection };
    default:
      return state;
  }
};
