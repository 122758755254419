import { SETUSER, SETUSERPERMISSIONS, SETISEDITINGUSER, LOGOUT, SETUSERS, SETISLOADINGUSERS } from '../actions/auth';

const initialState = {
  isEditingUser: false,
  isLoadingUsers: false,
  user: {},
  permissions: [],
  users: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETUSER:
      return {
        ...state,
        user: action.user
      };
    case SETUSERPERMISSIONS:
      return {
        ...state,
        permissions: action.permissions
      };
    case SETISEDITINGUSER:
      return {
        ...state,
        isEditingUser: action.isEditingUser
      };
    case SETISLOADINGUSERS:
      return {
        ...state,
        isLoadingUsers: action.isLoadingUsers
      };
    case LOGOUT:
      return {
        ...state,
        user: {}
      };
    case SETUSERS:
    return {
      ...state,
      users: action.users
    }
    default:
      return state;
  }
};
