import { SETISLOADINGINNETWORKS, SETNETWORKS, SETCURRENTNETWORK } from '../actions/networks';

const initialState = {
  isLoadingNetworks: false,
  networks: [],
  isAddingSite: false,
  isEditingSite: false,
  currentNetwork: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGINNETWORKS:
      return {
        ...state,
        isLoadingNetworks: action.isLoadingNetworks
      }
    case SETNETWORKS:
      return {
        ...state,
        networks: action.networks
      }
    case SETCURRENTNETWORK:
      return {
        ...state,
        currentNetwork: action.currentNetwork
      }
    default:
      return state;
  }
}
