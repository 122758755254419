import axios from 'axios';
import moment from 'moment';
import * as config from '../config';

export const USECASES = {
  AM: {
    id: 1,
    name: 'Audience Measurement'
  },
  SAMI: {
    id: 2,
    name: 'SAMIE'
  }
};

export const SETISLOADINGFEEDERS = 'feeders/SETISLOADINGFEEDERS';
export const SETFEEDERS = 'feeders/SETFEEDERS';

export const getFeeders = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGFEEDERS, isLoadingFeeders: true});

    axios(`${config.endpoints.base_url}v1/feeders`).then((resp) => {
      dispatch({type: SETISLOADINGFEEDERS, isLoadingFeeders: false});
      dispatch({type: SETFEEDERS, feeders: resp.data.data.rows});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getFeederByID = (feeder_id) => {
  return (dispatch, getState) => {
    if(getState().feeders.feeders) {
      const feeder = getState().feeders.feeders.find((feeder) => {
        return feeder.id === parseInt(feeder_id, 10);
      });
      if(feeder) {
        return feeder;
      }
    }
    return null;
  };
}

export const getFeederBy = (key, value) => {
  return (dispatch, getState) => {
    if(getState().feeders.feeders) {
      const feeder = getState().feeders.feeders.find((feeder) => {
        switch(typeof(feeder[key])) {
          case "object": // or array
            return feeder[key].indexOf(value) !== -1;
          case "number":
            return feeder[key] === value;
          case "string":
            return feeder[key] === value;
          default:
            break;
        }
        return false;
      });

      if(feeder) {
        return feeder;
      }
    }
    return null;
  };
}

export const STATUS = {
  ONLINE: { name: 'Online', from: 0, to: 1 },
  WARNING: { name: 'Warning', from: 1, to: 6 },
  OFFLINE: { name: 'Offline', from: 6, to: Number.POSITIVE_INFINITY },
  INACTIVE: { name: 'Inactive', from: -1 },
}

export const getFeederStatus = (updatedAt) => {
  let status = STATUS.INACTIVE;
  let duration = null;

  if(updatedAt) {
    duration = moment.duration(moment().diff(updatedAt));
    const hours = duration.asHours();

    if (hours >= STATUS.ONLINE.from && hours < STATUS.ONLINE.to) {
      status = STATUS.ONLINE;
    } else if (hours >= STATUS.WARNING.from && hours < STATUS.WARNING.to) {
      status = STATUS.WARNING;
    } else if (hours >= STATUS.OFFLINE.from && hours < STATUS.OFFLINE.to) {
      status = STATUS.OFFLINE;
    }
  }

  return status;

}

export const SETISEDITINGFEEDER = 'sites/SETISEDITINGFEEDER';

export const editFeeder = ({ id, feeder }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    const retransformedFeeder = retransformFeeder(feeder);
    dispatch({type: SETISEDITINGFEEDER, isEditingFeeder: true});
    axios.put(
      `${config.endpoints.base_url}feeders/${id}`,
      retransformedFeeder,
    ).then((resp) => {
      dispatch({type: SETISEDITINGFEEDER, isEditingFeeder: false});
      return onSuccess();
    }).catch((err) => {
      onError(err);
    })

  }
}

const retransformFeeder = (feeder) => {
  return {
    name: feeder.name,
    // site_id: feeder.site_id,
    location_id: feeder.location_id,
    description: feeder.description
  }
}

export const getFeedersBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().feeders.feeders) {
      const feeders = getState().feeders.feeders.filter((feeder) => {
        return feeder.site_id === parseInt(site_id, 10);
      });
      if(feeders) {
        return feeders;
      }
    }
    return null;
  };
}

export const getSceneFrame = (feeder_id = null, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    axios(`${config.endpoints.base_url}feeders/${feeder_id}/scene-frames`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getSceneFrameRequest = (feeder_id = null, onSuccess, onError) => {
  if(feeder_id) {
    axios(`${config.endpoints.base_url}feeders/${feeder_id}/scene-frames`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}
