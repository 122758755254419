import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as config from '../config';

export const SETISLOADINGINSITES = 'sites/SETISLOADINGINSITES';
export const SETSITES = 'sites/SETSITES';

export const getSites = (onSuccess = () => {}, onError = () => {}) => {

  return (dispatch) => {

    dispatch({ type: SETISLOADINGINSITES, isLoadingSites: true });

    axios(`${config.endpoints.base_url}v1/sites`).then((resp) => {
      dispatch({ type: SETISLOADINGINSITES, isLoadingSites: false });
      dispatch({ type: SETSITES, sites: transformSites(resp.data.data.rows) });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const transformSites = (sites) => {
  return sites.map(site => {
    return {
      ...site,
      location: {
        coordinates: {
          lat: parseFloat(site.site_attributes.find(attribute => attribute.attribute === 'lat')?.value),
          lng: parseFloat(site.site_attributes.find(attribute => attribute.attribute === 'lng')?.value),
        },
        street: site.site_attributes.find(attribute => attribute.attribute === 'street')?.value,
        suburb: site.site_attributes.find(attribute => attribute.attribute === 'suburb')?.value,
        state: site.site_attributes.find(attribute => attribute.attribute === 'state')?.value,
        postCode: site.site_attributes.find(attribute => attribute.attribute === 'postCode')?.value,
        country: site.site_attributes.find(attribute => attribute.attribute === 'country')?.value
      },
      image: site.site_attributes.find(attribute => attribute.attribute === 'image')?.value,
      blueprint: site.site_attributes.find(attribute => attribute.attribute === 'blueprint')?.value,
    };
  });
};

export const getSiteByID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().sites.sites) {
      const site = getState().sites.sites.find((site) => {
        return site.id === parseInt(site_id, 10);
      });
      if(site) {
        return site;
      }
    }
    return null;
  };
};

export const getSitesByNetworkID = (network_id) => {
  return (dispatch, getState) => {

    if(getState().sites.sites) {
      const sites = getState().sites.sites.filter((site) => {
        return site.network_id === parseInt(network_id, 10);
      });
      if(sites) {
        return sites;
      }
    }
    return null;
  };
};

export const SETISADDINGSITE = 'sites/SETISADDINGSITE';

export const addSite = ({ site, file, fileBlueprint }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {

    // const formData = new FormData();

    // formData.append('data', site);
    // if(file) {
    //   formData.append('image', file);
    // }

    // dispatch({type: SETISADDINGSITE, isAddingSite: true});
    // axios.post(
    //   `${config.endpoints.base_url}sites`,
    //   formData,
    //   {
    //     headers: { 'Content-Type': 'multipart/form-data' }
    //   }
    // ).then((resp) => {
    //   dispatch({type: SETISADDINGSITE, isAddingSite: false});
    //   return onSuccess();
    // }).catch((err) => {
    //   onError(err);
    // })

    dispatch({ type: SETISADDINGSITE, isAddingSite: true });

    if(file) {
      const formData = new FormData();

      formData.append('files', file);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      // console.log(fileRequest.data.data[0].url)
      site.image = fileRequest.data.data[0].url;
    }

    if(fileBlueprint) {
      const formData = new FormData();

      formData.append('files', file);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      // console.log(fileRequest.data.data[0])
      site.blueprint = fileRequest.data.data[0].url;
    }

    const retransformedSite = retransformSite(site);

    // console.log(retransformedSite)

    axios.post(
      `${config.endpoints.base_url}v1/sites`,
      retransformedSite
    ).then((resp) => {
      dispatch({ type: SETISADDINGSITE, isAddingSite: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });

  };
};

export const SETISEDITINGSITE = 'sites/SETISEDITINGSITE';

export const editSite = ({ id, site, file, fileBlueprint }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch) => {

    // const formData = new FormData();

    // formData.append('data', site);
    // if(file) {
    //   formData.append('image', file);
    // }

    // console.log(file)

    // dispatch({type: SETISEDITINGSITE, isEditingSite: true});
    // axios.put(
    //   `${config.endpoints.base_url}sites/${id}`,
    //   formData,
    //   {
    //     headers: { 'Content-Type': 'multipart/form-data' }
    //   }
    // ).then((resp) => {
    //   dispatch({type: SETISEDITINGSITE, isEditingSite: false});
    //   return onSuccess();
    // }).catch((err) => {
    //   onError(err);
    // })
    // console.log(id, site, file)

    dispatch({ type: SETISEDITINGSITE, isEditingSite: true });

    if(file) {
      const formData = new FormData();

      formData.append('files', file);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      // console.log(fileRequest.data.data[0])
      site.image = fileRequest.data.data[0].url;
    }

    if(fileBlueprint) {
      const formData = new FormData();

      formData.append('files', fileBlueprint);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      // console.log(fileRequest.data.data[0])
      site.blueprint = fileRequest.data.data[0].url;
    }

    const retransformedSite = retransformSite(site);
    axios.put(
      `${config.endpoints.base_url}v1/sites/${id}`,
      retransformedSite
    ).then(() => {
      dispatch({ type: SETISEDITINGSITE, isEditingSite: false });
      return onSuccess();
    }).catch((err) => {
      onError(err);
    });

  };
};

const retransformSite = (site) => {

  const site_attributes = [
    { attribute: 'lat', value: `${site.location.coordinates.lat}` },
    { attribute: 'lng', value: `${site.location.coordinates.lng}` },
    { attribute: 'street', value: site.location.street },
    { attribute: 'suburb', value: site.location.suburb },
    { attribute: 'state', value: site.location.state },
    { attribute: 'postCode', value: site.location.postCode },
    { attribute: 'country', value: site.location.country },
    { attribute: 'description', value: site.description },
    { attribute: 'pocName', value: site.pocName },
    { attribute: 'pocContact', value: site.pocContact },
    { attribute: 'pocEmail', value: site.pocEmail },
  ];

  if(site.image) {
    site_attributes.push({ attribute: 'image', value: site.image });
  }

  if(site.blueprint) {
    site_attributes.push({ attribute: 'blueprint', value: site.blueprint });
  }

  return {
    name: site.name,
    tags: site.tags,
    description: site.description,
    site_attributes
  };
};

export const sortSitesByLocation = (sites) => {
  return () => {
    const tree = [];

    if(sites) {
      sites.forEach((siteItem) => {
        const site = { ...siteItem };
        site.scenes = [];

        const { country } = site.location;
        const { state } = site.location;
        const { suburb } = site.location;

        if(country && state && suburb) {
          const countryIndex = tree.findIndex(i => i.name === country);
          if(countryIndex > -1) {
            const stateIndex = tree[countryIndex].states.findIndex(j => j.name === state);
            if(stateIndex > -1) {
              const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
              if(suburbIndex > -1) {
                tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
              } else {
                tree[countryIndex].states[stateIndex].suburbs.push({ id: uuidv4(), type: 'suburb', expand: true, name: suburb, sites: [], scenes: [] });
                const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
              }
            } else {
              tree[countryIndex].states.push({ id: uuidv4(), type: 'state', expand: true, name: state, suburbs: [], scenes: [] });

              const stateIndex = tree[countryIndex].states.findIndex(j => j.name === state);
              if(stateIndex > -1) {
                const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                if(suburbIndex > -1) {
                  tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                } else {
                  tree[countryIndex].states[stateIndex].suburbs.push({ id: uuidv4(), type: 'suburb', expand: true, name: suburb, sites: [], scenes: [] });
                  const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                  tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                }
              }
            }
          } else {
            tree.push({ id: uuidv4(), type: 'country', expand: true, name: country, states: [], scenes: [] });

            const countryIndex = tree.findIndex(i => i.name === country);
            if(countryIndex > -1) {
              const stateIndex = tree[countryIndex].states.findIndex(j => j.name === state);
              if(stateIndex > -1) {
                const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                if(suburbIndex > -1) {
                  tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                } else {
                  tree[countryIndex].states[stateIndex].suburbs.push({ id: uuidv4(), type: 'suburb', expand: true, name: suburb, sites: [], scenes: [] });
                  const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                  tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                }
              } else {
                tree[countryIndex].states.push({ id: uuidv4(), type: 'state', expand: true, name: state, suburbs: [], scenes: [] });

                const stateIndex = tree[countryIndex].states.findIndex(j => j.name === state);
                if(stateIndex > -1) {
                  const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                  if(suburbIndex > -1) {
                    tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                  } else {
                    tree[countryIndex].states[stateIndex].suburbs.push({ id: uuidv4(), type: 'suburb', expand: true, name: suburb, sites: [], scenes: [] });
                    const suburbIndex = tree[countryIndex].states[stateIndex].suburbs.findIndex(k => k.name === suburb);
                    tree[countryIndex].states[stateIndex].suburbs[suburbIndex].sites.push(site);
                  }
                }
              }
            }
          }
        }
      });
    }

    return tree;
  };
};

export const STATUS = {
  OUTOFSYNC: 'Out of Sync',
  INSYNC: 'In Sync'
};
