import iconUnknown from './icons_demographics/icon-unknown.svg';
import iconChildMale from './icons_demographics/icon-child-male.svg';
import iconChildFemale from './icons_demographics/icon-child-female.svg';
import iconYAdultMale from './icons_demographics/icon-y-adult-male.svg';
import iconYAdultFemale from './icons_demographics/icon-y-adult-female.svg';
import iconAdultMale from './icons_demographics/icon-adult-male.svg';
import iconAdultFemale from './icons_demographics/icon-adult-female.svg';
import iconSeniorMale from './icons_demographics/icon-senior-male.svg';
import iconSeniorFemale from './icons_demographics/icon-senior-female.svg';
import iconAnger from './icons_mood/icon-anger.svg';
import iconHappy from './icons_mood/icon-happy.svg';
import iconNeutral from './icons_mood/icon-neutral.svg';
import iconSad from './icons_mood/icon-sad.svg';
import iconSurprise from './icons_mood/icon-surprise.svg';

import { notification } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import *  as _ from 'lodash';
import React from 'react';

const AGECATEGORIES = [
  {
    age_category: 'unknown',
    gender: 'unknown',
    icon: iconUnknown,
    display: 'Unknown',
    description: 'Unknown'
  },
  {
    age_category: 'child',
    gender: 'male',
    icon: iconChildMale,
    display: 'Male<br/>Child',
    description: 'Male Child'

  },
  {
    age_category: 'child',
    gender: 'female',
    icon: iconChildFemale,
    display: 'Female<br/>Child',
    description: 'Female Child'
  },
  {
    age_category: 'young adult',
    gender: 'male',
    icon: iconYAdultMale,
    display: 'Male<br/>Y. Adult',
    description: 'Male Y. Adult'
  },
  {
    age_category: 'young adult',
    gender: 'female',
    icon: iconYAdultFemale,
    display: 'Female<br/>Y. Adult',
    description: 'Female Y. Adult'
  },
  {
    age_category: 'adult',
    gender: 'male',
    icon: iconAdultMale,
    display: 'Male<br/>Adult',
    description: 'Male Adult'
  },
  {
    age_category: 'adult',
    gender: 'female',
    icon: iconAdultFemale,
    display: 'Female<br/>Adult',
    description: 'Female Adult'
  },
  {
    age_category: 'senior',
    gender: 'male',
    icon: iconSeniorMale,
    display: 'Male<br/>Senior',
    description: 'Male Senior'
  },
  {
    age_category: 'senior',
    gender: 'female',
    icon: iconSeniorFemale,
    display: 'Female<br/>Senior',
    description: 'Female Senior'
  }
];

export function getAgeCategory(ageCategory = 'unknown', gender = 'unknown') {
  const AgeCategory = AGECATEGORIES.find(AGECATEGORY => {
    return (
      AGECATEGORY.age_category === ageCategory && AGECATEGORY.gender === gender
    );
  });

  if (AgeCategory) {
    return AgeCategory;
  }
  return AGECATEGORIES[0];
}

const MOODS = [
  { mood: 'neutral', icon: iconNeutral, display: 'Neutral' },
  { mood: 'happy', icon: iconHappy, display: 'Happy' },
  { mood: 'surprise', icon: iconSurprise, display: 'Surprised' },
  { mood: 'sad', icon: iconSad, display: 'Sad' },
  { mood: 'anger', icon: iconAnger, display: 'Angry' }
];

export function getMood(mood = 'neutral') {
  const Mood = MOODS.find(MOOD => {
    return MOOD.mood === mood;
  });

  if (Mood) {
    return Mood;
  }
  return MOODS[0];
}

export const createFormatAgeCategoryDisplay = (ageCategoryDisplay) => {
  const formatted = ageCategoryDisplay;
  return { __html: formatted };
};

export const toastNotification = (message, type) => {
  let icon = (_.isEqual(type, 'SUCCESS')) ? <CheckCircleFilled style={{ color: '#2dca71' }} /> : <ExclamationCircleFilled style={{ color: '#d21616' }} />;
  notification.open({
    message: message,
    icon: icon,
  });
}
