import { SETISLOADINGTAGS, SETTAGS } from '../actions/tags';

const initialState = {
  isLoadingTags: false,
  tags: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGTAGS:
      return {
        ...state,
        isLoadingTags: action.isLoadingTags
      }
    case SETTAGS:
      return {
        ...state,
        tags: action.tags
      }
    default:
      return state;
  }
}
