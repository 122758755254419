import axios from 'axios';
import * as config from '../config';

export const SETSIGNAGEVENDORS = 'signagevendor/SETSIGNAGEVENDORS';
export const SETVENDORPLAYER = 'signagevendor/SETVENDORPLAYER';
export const SETSIGNAGEDEVICES = 'signagevendor/SETSIGNAGEDEVICES';
export const ADDSIGNAGEDEVICES = 'signagevendor/ADDSIGNAGEDEVICES';
export const EDITSIGNAGEDEVICES = 'signagevendor/EDITSIGNAGEDEVICES';
export const SETINTERRUPTS = 'signagevendor/SETINTERRUPTS';
export const DELETESIGNAGEDEVICES = 'signagevendor/DELETESIGNAGEDEVICES';
export const SETISLOADINGINTERRUPTS = 'signagevendor/SETISLOADINGINTERRUPTS';
export const SETNETWORK = 'signagevendor/SETNETWORK';
export const ONERRORGETINTERRUPT = 'signagevendor/ONERRORGETINTERRUPT';
export const SETSIGNAGEPLAYERS = 'signagevendor/SETSIGNAGEPLAYERS';
export const ONERRORPAIRDEVICE = 'signagevendor/ONERRORPAIRDEVICE';
export const ONERRORUNPAIRDEVICE = 'signagevendor/ONERRORUNPAIRDEVICE';
export const ONINVALIDPAIRDEVICE = 'signagevendor/ONINVALIDPAIRDEVICE';
export const SETISLOADINGCHECKCONNECTION = 'signagevendor/SETISLOADINGCHECKCONNECTION';
export const SETCHECKCONNECTION = 'signagevendor/SETCHECKCONNECTION';
export const GENERALERROR = 'signagevendor/GENERALERROR';
export const SETISFETCHINGINTERRUPTS = 'signagevendor/SETISFETCHINGINTERRUPTS';
export const SETINTERRUPTSLIST = 'signagevendor/SETINTERRUPTSLIST';
export const ONERRORGETINTERRUPTLIST = 'signagevendor/ONERRORGETINTERRUPTLIST';
// export const getSignageVendors = (onSuccess = () => {}, onError = () => {}) => {
//   return (dispatch, getState) => {
//     axios(`${config.endpoints.base_url}signagevendors`).then((resp) => {
//       dispatch({ type: SETSIGNAGEVENDORS, signagevendors: resp.data.data.rows });
//       return onSuccess(resp.data);
//     }).catch((err) => {
//       onError(err);
//     })
//   };
// };

export const getSignageVendors = (
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios(`${config.endpoints.base_url}signagevendors`);

      if (onSuccess) {
        onSuccess(data.data.rows);
      }

      dispatch({ type: SETSIGNAGEVENDORS, signagevendors: data.data.rows });
    } catch (error) {
      // dispatch({ type: SETSIGNAGEVENDORS, signagevendors: [] });

      if (onError) {
        onError();
      }
    }
  };
};

// export const getVendorPlayers = ({ id }, onSuccess = () => {}, onError = () => {}) => {
//   return (dispatch, getState) => {
//      axios.get(`${config.endpoints.base_url}signagevendors/getVendorPlayers/${id}`).then((resp) => {
//       dispatch({ type: SETVENDORPLAYER, vendorplayers: resp.data.data });
//       return onSuccess(resp.data.data);
//     }).catch((err) => {
//       onError(err);
//     })
//   };
// };


// export const getVendorPlayers = (
//   id: any,
//   onSuccess?: (data: any) => void,
//   onError?: (error: any) => void
// ) => {
//   return async (dispatch, getState): Promise<void> => {
//     try {
//       const { data } = await   axios.get(`${config.endpoints.base_url}signagevendors/getVendorPlayers/${id}`)

//       if (onSuccess) {
//         onSuccess(data.data);
//       }

//     } catch ({ response }) {
//       if (onError) {
//         onError(response.data.message);
//       }
//     }
//   };
// };

export const getVendorPlayers = (
  id: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.get(`${config.endpoints.base_url}signagevendors/vendorplayers/${id}`)

      if (onSuccess) {
        onSuccess(data.data);
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      dispatch({ type: GENERALERROR });
      if (onError) {
        onError(response.data.message);
      }
    }
  };
};


const createTagPromise = (tag: any) => {
  return new Promise((resolve, reject) => {
    axios.post(`${config.endpoints.base_url}tags`, { name: tag })
      .then((resp) => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      })
  });
}



export const setPairDevice = (
  pairdetails: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      let tagList = getState().tags.tags;

      let newTags: any = [];
      let currentTags: any = [];
      pairdetails?.tags?.forEach((tag: any) => {
        let hasValue = tagList.find((t:any) => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });
    
      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach((r: any) => {
              currentTags.push(r.data.data.id);
            });
            pairdetails.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        pairdetails.tags = currentTags;
      }

      const { data } = await axios.post(`${config.endpoints.base_url}signagevendors/pairdevice`, pairdetails)

      const { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, is_active } = data.data;
      dispatch({
        type: ADDSIGNAGEDEVICES,
        signagedevices: { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, is_active }
      });
      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      const err = error as { response: any };
      let data = err?.response?.data || null;
      if (onError) {
        if (data && data.message === 'invalid_device') {
          dispatch({ type: ONINVALIDPAIRDEVICE });
        } else {
          dispatch({ type: ONERRORPAIRDEVICE });
        }
        onError(data || 'An error has occured while unpairing');
      }
    }
  };
}


export const setPairDevices = (
  pairdetails: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      let tagList = getState().tags.tags;

      let newTags: any = [];
      let currentTags: any = [];
      pairdetails?.tags?.forEach((tag: any) => {
        let hasValue = tagList.find((t:any) => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });
    
      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach((r: any) => {
              currentTags.push(r.data.data.id);
            });
            pairdetails.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        pairdetails.tags = currentTags;
      }

      const { data } = await axios.post(`${config.endpoints.base_url}signagevendors/pairdevice`, pairdetails)

      const { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, is_active } = data.data;
      dispatch({
        type: ADDSIGNAGEDEVICES,
        signagedevices: { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, is_active }
      });
      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      const err = error as { response: any };
      let data = err?.response?.data || null;
      if (onError) {
        if (data && data.message === 'invalid_device') {
          dispatch({ type: ONINVALIDPAIRDEVICE });
        } else {
          dispatch({ type: ONERRORPAIRDEVICE });
        }
        onError(data || 'An error has occured while unpairing');
      }
    }
  };
}


export const getSignageDevices = (
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    let state = getState();
    if (state?.signagevendors?.signagevendors[0]?.name === 'Broadsign') {
      console.log('No action - get signage devices if broadsign')
      // no action - Temporary Fix For Broadsign Integration causing error pop-ups
    } else {
      try {
        const { data } = await axios(`${config.endpoints.base_url}signagevendors/signagedevices`);
        if (onSuccess) {
          onSuccess(data.data.rows);
        }
  
        dispatch({ type: SETSIGNAGEDEVICES, signagedevices: data.data.rows });
      } catch (error) {
        dispatch({ type: SETSIGNAGEDEVICES, signagedevices: [] });
        dispatch({ type: GENERALERROR });
        if (onError) {
          onError();
        }
      }
    }
  };
};

export const updatePairDevice = (
  pairdetails: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.put(`${config.endpoints.base_url}signagevendors/updatePairDevice/${pairdetails.feeder_id}`, pairdetails)
      const { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, pair_tags } = data.data;
      dispatch({
        type: EDITSIGNAGEDEVICES,
        signagedevices: { id, network_id, device_id, feeder_id: parseInt(feeder_id), signagevendor_id, vendorplayer, vendorplayer_name, pair_tags }
      });
      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      if (onError) {
        onError(response.data.message);
      }
    }
  };
}

export const getInterrupts = (
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: true });
      const { data } = await axios(`${config.endpoints.base_url}signagevendors/interrupts`);

      if (onSuccess) {
        onSuccess(data.data.rows);
        dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: false });
        dispatch({ type: SETINTERRUPTS, interrupts: data.data.rows });
      }


    } catch (error) {
      dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: false });
      dispatch({ type: GENERALERROR });
      if (onError) {
        onError();
      }
    }
  };
};


export const unPairDevice = (
  signagedevicedetails: any,
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { id } = signagedevicedetails;
      const { data } = await axios.delete(`${config.endpoints.base_url}signagevendors/unpairdevice/${id}`);
      if (onSuccess) {
        onSuccess(data.data);
      }
      dispatch({ type: DELETESIGNAGEDEVICES, signagedevices: signagedevicedetails });
    } catch (error) {
      if (onError) {
        onError();
        dispatch({ type: ONERRORUNPAIRDEVICE });
      }
    }
  };
};

export const getInterrupt = (
  vendorplayer_id: number,
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: true });
      const { data } = await axios(`${config.endpoints.base_url}signagevendors/interrupts/${vendorplayer_id}`);

      if (onSuccess) {
        onSuccess(data.data.rows);
        dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: false });
        dispatch({ type: SETNETWORK, network: data.network });
      }


    } catch (error) {
      dispatch({ type: SETISLOADINGINTERRUPTS, isLoadingInterrupts: false });
      if (onError) {
        onError();
      }
    }
  };
};


export const onErrorGetInterrupt = () => {
  return async (dispatch, getState): Promise<void> => {
    dispatch({ type: ONERRORGETINTERRUPT });
  };
};

export const getSignagePlayers = (
  onSuccess?: (data: Array<any>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    let state = getState();
    if (state?.signagevendors?.signagevendors[0]?.name === 'Broadsign') {
      console.log('No action - get signage signage players if broadsign')
      // no action - Temporary Fix For Broadsign Integration causing error pop-ups
    } else {
      try {
        const { data } = await axios(`${config.endpoints.base_url}signagevendors/signageplayers`);
  
        if (onSuccess) {
          onSuccess(data.data.rows);
        }
  
        dispatch({ type: SETSIGNAGEPLAYERS, signageplayers: data.data.rows });
      } catch (error) {
        dispatch({ type: GENERALERROR });
        if (onError) {
          onError();
        }
      }
    }
  };
};

export const checkConnection = (
  feederDetails: any,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {

      const { feeder_id, signagevendorplayer_name } = feederDetails;

      dispatch({ type: SETISLOADINGCHECKCONNECTION, isLoadingCheckConnection: true });

      const { data } = await axios.post(`${config.endpoints.base_url}signagevendors/checkConnection`, { feeder_id });

      if (onSuccess) {
        onSuccess(data.data);
        dispatch({ type: SETISLOADINGCHECKCONNECTION, isLoadingCheckConnection: false });
        dispatch({ type: SETCHECKCONNECTION, isReachable: data.data.is_reachable, signagevendorplayer_name });
      }

    } catch (error) {
      const response = (error as any).response; // Explicitly cast error to any to access response object
      if (onError) {
        onError(response.data.message);
        dispatch({ type: SETISLOADINGCHECKCONNECTION, isLoadingCheckConnection: false });
        dispatch({ type: GENERALERROR });
      }
    }
  };
}

export const updatePairTags = (
  payload: {
    tagIds: number[],
    signageDeviceId: number,
  },
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  const { signageDeviceId, tagIds } = payload;

  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.put(`${config.endpoints.base_url}signagevendors/updatePairTags/${signageDeviceId}`, { tags: tagIds });
      const { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, pair_tags } = data.data;

      dispatch({
        type: EDITSIGNAGEDEVICES,
        signagedevices: { id, network_id, device_id, feeder_id, signagevendor_id, vendorplayer, vendorplayer_name, pair_tags }
      });
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };
};