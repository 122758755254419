import { SETISLOADINGDEVICE, SETDEVICETYPE } from '../actions/devicetype';

const initialState = {
  isLoadingDevice: false,
  devicetype: [],
  activation_types: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGDEVICE:
      return {
        ...state,
        isLoadingDevice: action.isLoadingDevice
      }
    case SETDEVICETYPE:
      return {
        ...state,
        devicetype: action.devicetype,
        activation_types: action.activation_types
      }
    default:
      return state;
  }
}
