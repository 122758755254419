import *  as _ from 'lodash';
import {
  ADDTARGETSTRATEGY,
  ADDTARGETSEGMENT,
  ARCHIVETARGETSTRATEGY,
  UPDATETARGETSTRATEGY,
  DELETETARGETSEGMENT
} from '../actions/targetingstrategy';
import { toastNotification } from '../actions/utils';
import {
  TARGETSEGMENTTOAST,
  TARGETSTRATEGYTOAST,
  SUCCESS,
  ARCHIVETARGETSTRATEGYTOAST,
  UPDATETARGETSTRATEGYTOAST,
  DELETETARGETSEGMENTTOAST
} from '../utils/toastmessages';
import { _filter } from '../utils/common';
const _targetstrategy = (store) => (next) => (action) => {
  let { type, targetstrategy } = action;

  if (_.isEqual(type, ADDTARGETSTRATEGY)) {
    const { signagevendors } = store.getState();
    const { signagedevices } = signagevendors;

    action.targetstrategy = { ...targetstrategy, is_paired: true };

    toastNotification(TARGETSTRATEGYTOAST, SUCCESS);
  }
  next(action);
}

const targetsegment = (store) => (next) => (action) => {
  const { type, isCreate } = action;

  if (_.isEqual(type, ADDTARGETSEGMENT)) {
    if (isCreate) {
      toastNotification(TARGETSEGMENTTOAST, SUCCESS);
    }
  }
  next(action);
}

const deleteTargetSegment = (store) => (next) => (action) => {
  const { type, isCreate } = action;
  if (_.isEqual(type, DELETETARGETSEGMENT)) {
    toastNotification(DELETETARGETSEGMENTTOAST, SUCCESS);
  }
  next(action);
}

const archivetargetstrategy = (store) => (next) => (action) => {
  const { type } = action;

  if (_.isEqual(type, ARCHIVETARGETSTRATEGY)) {
    toastNotification(ARCHIVETARGETSTRATEGYTOAST, SUCCESS);
  }

  next(action);
}

const updatetargetstrategy = (store) => (next) => (action) => {
  const { type, targetstrategy } = action;

  if (_.isEqual(type, UPDATETARGETSTRATEGY)) {

    delete targetstrategy.signage_device;
    const { signagevendors, targetingstrategy } = store.getState();

    toastNotification(UPDATETARGETSTRATEGYTOAST, SUCCESS);
  }

  next(action);
}

export { _targetstrategy, targetsegment, archivetargetstrategy, updatetargetstrategy, deleteTargetSegment };
