export const ADDMODEL = 'blender/ADDMODEL';
export const ADDLIST = 'blender/ADDLIST';

export const addBlend = (payload) => dispatch => {
	console.log('payload:',payload)
    dispatch({type: ADDMODEL, models: payload});
}

export const addList = (payload) => dispatch => {
	console.log('--', payload)
    dispatch({type: ADDLIST, list: payload});
}
