export const SET_USECASES = 'useCases/SET_USECASES';

export const setUseCases = (useCases, callback) => {
  return (dispatch) => {
    dispatch({ type: SET_USECASES, useCases });

    if (callback) {
      return callback();
    }
  };
};
