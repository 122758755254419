import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGLOCATIONS = 'locations/SETISLOADINGLOCATIONS';
export const SETLOCATIONS = 'locations/SETLOCATIONS';

export const getLocations = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGLOCATIONS, isLoadingLocations: true});

    axios(`${config.endpoints.base_url}locations`).then((resp) => {
      dispatch({type: SETISLOADINGLOCATIONS, isLoadingLocations: false});
      dispatch({type: SETLOCATIONS, locations: transformLocations(resp.data.data.rows)});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformLocations = (locations) => {
  return locations.map(location => {
    return {
      ...location
    }
  })
}

export const getLocationByID = (location_id) => {
  return (dispatch, getState) => {
    if(getState().locations.locations) {
      const location = getState().locations.locations.find((location) => {
        return location.id === parseInt(location_id, 10);
      });
      if(location) {
        return location;
      }
    }
    return null;
  };
}

export const getLocationsByNetworkID = (network_id) => {
  return (dispatch, getState) => {
  };
}


export const getLocationsBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().locations.locations) {
      const locations = getState().locations.locations.filter((location) => {
        return location.site_id === parseInt(site_id, 10);
      });
      if(locations) {
        return locations;
      }
    }
    return null;
  };
}

export const SETISADDINGLOCATION = 'sites/SETISADDINGLOCATION';

export const addLocation = ({ zone }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    const retransformedLocation = retransformLocation(zone);
    dispatch({type: SETISADDINGLOCATION, isAddingLocation: true});
    axios.post(
      `${config.endpoints.base_url}locations`,
      retransformedLocation
    ).then((resp) => {
      dispatch({type: SETISADDINGLOCATION, isAddingLocation: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const SETISEDITINGLOCATION = 'sites/SETISEDITINGLOCATION';

export const editLocation = ({ zoneID, zone }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    const retransformedLocation = retransformLocation(zone);
    dispatch({type: SETISEDITINGLOCATION, isEditingLocation: true});
    axios.put(
      `${config.endpoints.base_url}locations/${zoneID}`,
      retransformedLocation
    ).then((resp) => {
      dispatch({type: SETISEDITINGLOCATION, isEditingLocation: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

const retransformLocation = (location) => {
  return {
    name: location.name,
    site_id: parseInt(location.site_id, 10),
    description: location.description,
    tags: location.tags
  }
}

export const SETISREMOVINGLOCATION = 'sites/SETISREMOVINGLOCATION';

export const removeLocation = ({ id }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISREMOVINGLOCATION, isRemovingLocation: true});
    axios.delete(
      `${config.endpoints.base_url}locations/${id}`
    ).then((resp) => {
      dispatch({type: SETISREMOVINGLOCATION, isRemovingLocation: false});
      return onSuccess();
    }).catch((err) => {
      onError(err);
    })

  }
}
