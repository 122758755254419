import { SETISLOADINGINSIGHTS, SETISLOADINGDEMOGRAPHICS } from '../actions/insights';

const initialState = {
  isLoadingInsights: false,
  isLoadingDemographics: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGINSIGHTS:
      return {
        ...state,
        isLoadingInsights: action.isLoadingInsights
      }
    case SETISLOADINGDEMOGRAPHICS:
      return {
        ...state,
        isLoadingDemographics: action.isLoadingDemographics
      }
    default:
      return state;
  }
}
