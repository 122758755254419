export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const TARGETSEGMENTTOAST = 'Target segment has been successfully added.';
export const DELETETARGETSEGMENTTOAST = 'Target Segment has been successfully deleted.';
export const TARGETSTRATEGYTOAST = 'Targeting Strategy has been successfully saved.';

export const ARCHIVETARGETSTRATEGYTOAST = 'Targeting Strategy has been successfully archived.';
export const UPDATETARGETSTRATEGYTOAST = 'Targeting Strategy has been successfully updated.';

export const SETPAIRDEVICETOAST = 'Signage display has been successfully paired.';
export const SETUNPAIRDEVICETOAST = 'Signage display has been successfully unpaired.';
export const ONERRORPAIRDEVICETOAST = 'There has been an error pairing your signage display.';
export const ONERRORUNPAIRDEVICETOAST = 'There has been an error unpairing your signage display.';
export const ONINVALIDDEVICETOAST = 'Signage display can only be paired again if the sensor is connected to the same device.';

export const GENERALERRORTOAST = 'Something went wrong, please try again.';

export const ONSUCCESSCHECKCONNECTIONREACHABLE = 'Connection to ~~ is active.';
export const ONSUCCESSCHECKCONNECTIONNOTREACHABLE = 'Failed to connect to ~~. Please make sure it is currently online.';
