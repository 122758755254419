import { SETISLOADINGINSITES, SETSITES, SETISADDINGSITE, SETISEDITINGSITE } from '../actions/sites';

const initialState = {
  isLoadingSites: false,
  sites: [],
  isAddingSite: false,
  isEditingSite: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGINSITES:
      return {
        ...state,
        isLoadingSites: action.isLoadingSites
      }
    case SETSITES:
      return {
        ...state,
        sites: action.sites
      }
    case SETISADDINGSITE:
      return {
        ...state,
        isAddingSite: action.isAddingSite
      }
    case SETISEDITINGSITE:
      return {
        ...state,
        isEditingSite: action.isEditingSite
      }
    default:
      return state;
  }
}
