
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faChevronRight, faChevronLeft, faEllipsisH, faPlus, faMinus, faTimes, faCaretDown, faCaretRight, faFlag, faLandmark, faCity, faList, faMapMarkedAlt, faSort, faSortUp, faSortDown, faSearch, faFilter, faMapMarkerAlt, faUserCircle, faPhoneAlt, faEnvelope, faUpload, faRedo, faCheckSquare, faCheckCircle, faCheck, faTimesCircle, faInfoCircle, faSignOutAlt, faEye, faEyeSlash, faLink, faCalendar, faChevronDown, faPenNib, faPen, faTrashAlt, faSquare, faThLarge, faSync, faBars, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCommentAlt, faCheckCircle as farCheckCircle, faCircle, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export const addIconLibrary = () => {
  library.add(
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faEllipsisH,
    faMinus,
    faPlus,
    faTimes,
    faCaretDown,
    faCaretRight,
    faFlag,
    faLandmark,
    faCity,
    faList,
    faMapMarkedAlt,
    faSort,
    faSortUp,
    faSortDown,
    faSearch,
    faFilter,
    faMapMarkerAlt,
    faUserCircle,
    faPhoneAlt,
    faEnvelope,
    faUpload,
    faRedo,
    faCheck,
    faCheckSquare,
    faCheckCircle,
    faTimesCircle,
    faInfoCircle,
    faSignOutAlt,
    faEye,
    faEyeSlash,
    faLink,
    faCalendar,
    faClock,
    faCommentAlt,
    faChevronDown,
    farCheckCircle,
    faCircle,
    faSquare,
    farSquare,
    faPenNib,
    faPen,
    faTrashAlt,
    faThLarge,
    faSync,
    faBars,
    faExclamationCircle
  );
};
